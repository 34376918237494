import Environment from "../../../../../Environment";

export default function useCompanyInfo(){
	return {
		description: 'Exchange money with no hidden fees. Buy crypto, gold and manage your money with spending analytics. Join over 1 million Papel customers.',
		phoneNumber: Environment.CONTACT_INFORMATION.PHONE_NUMBER,
		address: Environment.CONTACT_INFORMATION.ADDRESS,
		emailAddress: Environment.CONTACT_INFORMATION.EMAIL,
	}
}
