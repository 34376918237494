export const LOGIN_ROUTE = 'https://my.papel.eu/session/login';
export const REGISTER_ROUTE = 'https://my.papel.eu/session/register';
export const BUY_CRYPTO_ROUTE = 'https://my.papel.eu/customer/exchange';
export const DEPOSIT_ROUTE = 'https://my.papel.eu/customer/deposit/bank-transfer';

export const HOMEPAGE_ROUTE = '/';

export const CREDIT_ROUTE = '/credit';
export const AGGREGATE_ROUTE = '/aggregator';
export const VIP_REWARDS_ROUTE = '/rewards';
export const STAKING_ROUTE = '/staking';
export const QUICK_BUY_ROUTE = '/quick-buy';

export const MOBILE_APP_ROUTE = '/mobile-app';

export const CONTACT_US_ROUTE = '/contact-us';
export const BRAND_GUIDELINES_ROUTE = '/brand-guidelines';
export const ABOUT_US_ROUTE = '/about';
export const BLOG_ROUTE = 'https://papel.eu/blog';

export const KYC_POLICY_ROUTE = '/kyc-policy';
export const AML_POLICY_ROUTE = '/aml-policy';
export const REFUND_POLICY_ROUTE = '/refund-policy';
export const GDPR_POLICY_ROUTE = '/gdpr-policy';
export const TERMS_OF_SERVICE_ROUTE = '/terms-of-service';

const LEGAL_ROUTES  = [
	KYC_POLICY_ROUTE,
	AML_POLICY_ROUTE,
	REFUND_POLICY_ROUTE,
	GDPR_POLICY_ROUTE,
	TERMS_OF_SERVICE_ROUTE,
];

export const ALL_ROUTES = [
	HOMEPAGE_ROUTE,
	CREDIT_ROUTE,
	AGGREGATE_ROUTE,
	VIP_REWARDS_ROUTE,
	STAKING_ROUTE,
	QUICK_BUY_ROUTE,
	MOBILE_APP_ROUTE,
	CONTACT_US_ROUTE,
	BRAND_GUIDELINES_ROUTE,
	ABOUT_US_ROUTE,
	...LEGAL_ROUTES
];
