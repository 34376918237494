import React from 'react';
import {
	FooterLinksSectionContainer,
	FooterLinksSectionItem,
	FooterLinksSectionItemsLinksContainer,
	FooterLinksSectionItemsLinkText,
	FooterLinksSectionTitle
} from "./components/Styled";

export default function FooterLinksSection({linkSections}){

  return (
    <FooterLinksSectionContainer>
	    {linkSections.map((section, index) =>
		    <FooterLinksSectionItem key={`section-item-index-${index}`} isFirstItem={index === 0}>
			    <FooterLinksSectionTitle>{section.title}</FooterLinksSectionTitle>
			    <FooterLinksSectionItemsLinksContainer>
				    {section.links.map((link, linkIndex) =>
					    <FooterLinksSectionItemsLinkText {...link} key={`link-item-index-${linkIndex}`}>
						    {link.title}
					    </FooterLinksSectionItemsLinkText>)}
			    </FooterLinksSectionItemsLinksContainer>
		    </FooterLinksSectionItem>)}
    </FooterLinksSectionContainer>
  );
};
