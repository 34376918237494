import styled from 'styled-components';

export const ExchangeRateContainer = styled.div`
	display: flex;
	flex-grow: 1;
	justify-content: center;
	align-items: center;
`;

export const ExchangeRateItemContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 8px 10px 0;
`;

export const ExchangeRateItemIcon = styled.img`
	width: 20px;
	height: 20px;
	margin-right: 10px;
`;
