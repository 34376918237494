import {getRouteMappingsToScreen} from "./RouteToScreenMapping";
import {getRoutesAvailable} from "./RoutesAvailableMapping";
import {getRouteOptions} from "./RouteOptionsMapping";

export default function getApplicationRoutes(){
	const routesMappedToScreen = getRouteMappingsToScreen();
	const availableRoutes = getRoutesAvailable();

	return availableRoutes.map((routeName) => {
		return {
			path: routeName,
			component: routesMappedToScreen[routeName],
			...getRouteOptions(routeName)
		}
	})
}
