import React from 'react';

import ExchangeRateLoop from "./components/ExchangeRateLoop";
import { CurrencySelectorWrapper, TopBarContentContainer } from "./components/Styled";
import WidthLimitContainer from "../../../../../../../components/Layout/WidthLimitContainer";
import TopBarCurrencySelector from "./components/TopBarCurrencySelector";

const TopBarComponent = () => {
  return (
	  <WidthLimitContainer>
		  <TopBarContentContainer>
			  <ExchangeRateLoop />
			  <div className={'flex'}>
				  <CurrencySelectorWrapper>
					  <TopBarCurrencySelector size={'small'}/>
				  </CurrencySelectorWrapper>
			  </div>
		  </TopBarContentContainer>
	  </WidthLimitContainer>
  );
};

export default TopBarComponent;
