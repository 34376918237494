import React, { useContext } from "react";
import CurrencySelector
	from "../../../../../../../../../module/Currency/components/CurrencySelector";
import { FiatCurrencyList } from "../../../../../../../../../module/Currency/FiatCurrency/FiatCurrencyListProvider";

const TopBarCurrencySelector = ({}) => {
	const{currencies, defaultCurrency, setDefaultCurrency} = useContext(FiatCurrencyList);
	const handleValueChange = (code) => setDefaultCurrency(code);

  return (
	  <CurrencySelector
		  showCode={false}
		  options={currencies}
		  selectedCurrencyCode={defaultCurrency}
		  onChange={handleValueChange}
	  />
  );
};

export default TopBarCurrencySelector;
