import {DEVELOPMENT_ENVIRONMENT, STAGING_ENVIRONMENT, PRODUCTION_ENVIRONMENT} from '../EnvironmentConstants';

const ENV_FILES_BY_BUILD_TYPE = {
  common: require('./../envs/common.json'),
  [DEVELOPMENT_ENVIRONMENT]: require('./../envs/development.json'),
  [STAGING_ENVIRONMENT]: require('./../envs/staging.json'),
  [PRODUCTION_ENVIRONMENT]: require('./../envs/production.json'),
};

export default function getBuildTypeEnvironmentVariables(buildType) {
  return Object.assign(
    {
      IS_DEVELOPMENT_ENVIRONMENT: buildType === DEVELOPMENT_ENVIRONMENT,
      IS_PRODUCTION_ENVIRONMENT: buildType === PRODUCTION_ENVIRONMENT,
      IS_STAGING_ENVIRONMENT: buildType === STAGING_ENVIRONMENT,
    },
    ENV_FILES_BY_BUILD_TYPE.common,
    ENV_FILES_BY_BUILD_TYPE[buildType],
  );
}
