import React, { useContext } from "react";

import LoopSlider from "../../../../../../../../../components/UI/Sliders/Loop";
import { ExchangeStateContext } from "../../../../../../../../../module/Exchange/ExchangeStateProvider";
import { FiatCurrencyList } from "../../../../../../../../../module/Currency/FiatCurrency/FiatCurrencyListProvider";

import {
	ExchangeRateContainer,
	ExchangeRateItemContainer,
	ExchangeRateItemIcon,
} from "./components/Styled";

const ExchangeRateLoop = () => {
	const {exchangeRate} = useContext(ExchangeStateContext);
	const{defaultCurrency} = useContext(FiatCurrencyList);

	const getExchangeLoopContent = () => {
		return exchangeRate.map((exchange, index) => {
			if(exchange.currency_code !== defaultCurrency) {
				return null;
			}

			return <ExchangeRateItemContainer key={index}>
				<ExchangeRateItemIcon src={exchange.icon}  alt=''/>
				<div>{exchange.name} ({exchange.cryptocurrency_code}) {exchange.currency_code} {exchange.sale_rate}</div>
			</ExchangeRateItemContainer>
		})
	};

	return (
		<ExchangeRateContainer>
			<LoopSlider>
				{getExchangeLoopContent()}
			</LoopSlider>
		</ExchangeRateContainer>
	);
};

export default ExchangeRateLoop;
