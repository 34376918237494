import bitcoin from './assets/bitcoin.svg';
import bitcoin_cash from './assets/bitcoin_cash.svg';
import litcoin from './assets/litcoin.svg';

import usdIcon from './assets/fiat-currency-icons/usd-icon.png';

export const CURRENCY_CODE_TRY = 'TRY';
export const CURRENCY_CODE_USD = 'USD';
export const CURRENCY_CODE_EUR = 'EUR';

export const CRYPTO_CURRENCY_CODE_BTC = 'BTC'
export const CRYPTO_CURRENCY_CODE_BCH = 'BCH'
export const CRYPTO_CURRENCY_CODE_LTC = 'LTC'

export const CRYPTO_CURRENCIES_EXCHANGE_INDEXES = {
	[CRYPTO_CURRENCY_CODE_BTC]: 1,
	[CRYPTO_CURRENCY_CODE_BCH]: 2,
	[CRYPTO_CURRENCY_CODE_LTC]: 3,
}

export const FIAT_CURRENCIES_EXCHANGE_INDEXES = {
	// [CURRENCY_CODE_TRY]: 1,
	[CURRENCY_CODE_EUR]: 2,
	// [CURRENCY_CODE_USD]: 2,
}

export const CRYPTO_CURRENCY_EXTEND = {
	[CRYPTO_CURRENCY_CODE_BTC]: {
		name: "Bitcoin",
		coinGeckoId: 'bitcoin',
		code: CRYPTO_CURRENCY_CODE_BTC,
		currency: CURRENCY_CODE_EUR,
		icon: bitcoin,
		exchangeIndex: CRYPTO_CURRENCIES_EXCHANGE_INDEXES[CRYPTO_CURRENCY_CODE_BTC],
	},
	[CRYPTO_CURRENCY_CODE_BCH]: {
		name: "Bitcoin Cash",
		coinGeckoId: 'bitcoin-cash',
		code: CRYPTO_CURRENCY_CODE_BCH,
		currency: CURRENCY_CODE_EUR,
		icon: bitcoin_cash,
		exchangeIndex: CRYPTO_CURRENCIES_EXCHANGE_INDEXES[CRYPTO_CURRENCY_CODE_BCH],
	},
	[CRYPTO_CURRENCY_CODE_LTC]: {
		name: "Litecoin",
		coinGeckoId: 'litecoin',
		code: CRYPTO_CURRENCY_CODE_LTC,
		currency: CURRENCY_CODE_EUR,
		icon: litcoin,
		exchangeIndex: CRYPTO_CURRENCIES_EXCHANGE_INDEXES[CRYPTO_CURRENCY_CODE_LTC],
	},
}

export const FIAT_CURRENCIES = {
	[CURRENCY_CODE_USD]: {
		name: "Dollar",
		code: CURRENCY_CODE_USD,
		symbol: '$',
		icon: usdIcon,
		exchangeIndex: CRYPTO_CURRENCIES_EXCHANGE_INDEXES[CURRENCY_CODE_USD],
	},
	[CURRENCY_CODE_EUR]: {
		name: "Euro",
		code: CURRENCY_CODE_EUR,
		symbol: '€',
		icon: bitcoin_cash,
		exchangeIndex: CRYPTO_CURRENCIES_EXCHANGE_INDEXES[CURRENCY_CODE_EUR],
	}
}

export const DEFAULT_FIAT_CURRENCY = CURRENCY_CODE_EUR;
export const DEFAULT_CRYPTO_CURRENCY = CRYPTO_CURRENCY_CODE_BTC;
