import React, { useState } from "react";
import CurrencySelectorList from "./components/CurrencySelectorList";

import { KeyboardArrowDown} from "@material-ui/icons";

const CurrencySelector = ({options, selectedCurrencyCode, onChange, showFullName = true, showCode = true, showFullNameOnItemList = true}) => {
	const selectedOption = options.filter(item => item.code === selectedCurrencyCode).pop();
	const hasOptionSelected = selectedOption !== undefined;

	const [isOpened, setIsOpened] = useState(false);

	const itemClickHandler = (code) => {
		onChange && onChange(code);
		setTimeout(() => setIsOpened(false), 10);
	}

  return (
	  <div className={'w-full'}>
		  <div className="relative group w-full">
			  <button
				  onBlur={() => setTimeout(() => setIsOpened(false), 300)}
				  onClick={() => setIsOpened(!isOpened)}
				  type="button"
				  aria-haspopup="listbox"
				  aria-expanded={isOpened}
          aria-labelledby="currency-selector-list"
          className="relative w-full bg-white pl-3 pr-10 py-2 text-left cursor-default focus:outline-none sm:text-sm"
			  >
				  <span className="flex items-center">
					  {hasOptionSelected === true && (
						  <img
							  src={selectedOption.icon}
							  alt="" className="flex-shrink-0 h-6 w-6 rounded-full"
						  />
					  )}
		        <span className="ml-3 block truncate">
			        {hasOptionSelected && (
			        	<span className={'space-x-2'}>
					        {showFullName === true && (
					        	<span className={'font-medium uppercase'}>{selectedOption.name}</span>
					        )}
					        {showCode === true && (
						        <span className={'font-medium text-gray-400'}>{selectedOption.code}</span>
					        )}
				        </span>
			        ) || 'Please select'}
		        </span>
          </span>
				  <span
					  className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none text-gray-400">
					  <KeyboardArrowDown color={'inherit'} />
          </span>
			  </button>

			  <div
				  className={`absolute w-full rounded-md bg-white shadow-lg z-10 ${isOpened ? 'block' : 'hidden'}`}
			  >
				  <CurrencySelectorList
					  options={options}
					  selectedCurrencyCode={selectedCurrencyCode}
					  itemClickHandler={itemClickHandler}
					  showFullName={showFullNameOnItemList}
				  />
			  </div>
		  </div>
	  </div>
  );
};

export default CurrencySelector;
