import styled from "styled-components";
import {Link} from "react-router-dom";

export const FooterLinksSectionContainer = styled.div`
	display: flex;
  flex-direction: row;
	justify-content: flex-end;

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.sm)} {
		flex-wrap: wrap;
		margin-left: 30px;
	}

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.md)} {
		margin-left: 0;
		margin-top: 50px;
		justify-content: space-between;
		flex-direction: column;
		flex-wrap: wrap;
	}
`;

export const FooterLinksSectionItem = styled.div`
	margin-left: ${props => props.isFirstItem === true ? 0 : 60}px;
	
	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.md)} {
		flex: 1;
		margin: 0 30px 30px 30px;
	}

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.md)} {
		margin: 0 0 30px 0;
	}
`;

export const FooterLinksSectionTitle = styled.div`
	font-size: 25px;
	line-height: 26px;
	font-weight: 500;
	color: white;
	margin-bottom: 30px;
`;

export const FooterLinksSectionItemsLinksContainer = styled.div`
	cursor: pointer;
`;

export const FooterLinksSectionItemsLinkText = styled(Link)`
	display: flex;
	color: inherit;
	text-decoration: none;
	font-size: 17px;
	line-height: 25px;
	font-weight: 300;
	margin-top: 10px;
	
	&:hover {
		text-decoration: underline;
	}
`;
