import React from 'react';
import {Facebook, Instagram, LinkedIn, Twitter, YouTube} from "@material-ui/icons";

import {
	CopyrightText,
	FooterCopyrightBarContainer,
	SocialIconItem,
	SocialIconsContainer
} from "./components/Styled";

export default function FooterCopyrightBar(){
  return (
    <FooterCopyrightBarContainer>
	    <CopyrightText>Copyright © {(new Date()).getFullYear()} Papel.EU | All rights reserved.</CopyrightText>
      <SocialIconsContainer>
	      <SocialIconItem>
		      <Facebook />
	      </SocialIconItem>
	      <SocialIconItem>
		      <Twitter />
	      </SocialIconItem>
	      <SocialIconItem>
		      <Instagram />
	      </SocialIconItem>
	      <SocialIconItem>
		      <LinkedIn />
	      </SocialIconItem>
	      <SocialIconItem>
		      <YouTube />
	      </SocialIconItem>
      </SocialIconsContainer>
    </FooterCopyrightBarContainer>
  );
};
