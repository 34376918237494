import React, { createContext, useEffect, useState } from "react";
import { DEFAULT_FIAT_CURRENCY, FIAT_CURRENCIES } from "../CurrencyConstants";

const initialState = {
	currencies: [],
};

export const FiatCurrencyList = createContext(initialState);
export const FiatCurrencyListProvider = ({children}) => {
	const [currencies, setCurrencies] = useState(initialState.currencies);
	const [defaultCurrency, setDefaultCurrency] = useState(DEFAULT_FIAT_CURRENCY);

	useEffect(() => {
		setCurrencies(Object.entries(FIAT_CURRENCIES).map(item => item[1]));
	}, []);

	return (
		<FiatCurrencyList.Provider value={{currencies, defaultCurrency, setDefaultCurrency}}>
			{children}
		</FiatCurrencyList.Provider>
	);
};
