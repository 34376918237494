import React, {lazy} from "react";
import Environment from "../Environment";

import {
	ABOUT_US_ROUTE,
	AGGREGATE_ROUTE,
	AML_POLICY_ROUTE,
	BRAND_GUIDELINES_ROUTE,
	CONTACT_US_ROUTE,
	CREDIT_ROUTE,
	GDPR_POLICY_ROUTE,
	HOMEPAGE_ROUTE,
	KYC_POLICY_ROUTE,
	MOBILE_APP_ROUTE,
	QUICK_BUY_ROUTE,
	REFUND_POLICY_ROUTE,
	STAKING_ROUTE,
	TERMS_OF_SERVICE_ROUTE,
	VIP_REWARDS_ROUTE,
} from "./NavigationConstants";
import {DEVELOPMENT_ENVIRONMENT, PRODUCTION_ENVIRONMENT, STAGING_ENVIRONMENT} from "../Environment/EnvironmentConstants";

import ComingSoonScreen from "../../screens/ComingSoonScreen";

//Static Screens
const HomeScreen = lazy(() => import("../../module/StaticPages/screens/HomeScreen"));
const LoansScreen = lazy(() => import("../../module/StaticPages/screens/LoansScreen"));
const ContactUsScreen = lazy(() => import("../../module/StaticPages/screens/ContactUsScreen"));
const MobileAppScreen = lazy(() => import("../../module/StaticPages/screens/MobileAppScreen"));
const AboutUsScreen = lazy(() => import("../../module/StaticPages/screens/AboutUsScreen"));
const AggregatorPage = lazy(() => import("../../module/StaticPages/screens/AggregatorScreen"));
const QuickBuyScreen = lazy(() => import("../../module/StaticPages/screens/QuickBuyScreen/container"));
const StakingScreen = lazy(() => import("../../module/StaticPages/screens/StakingScreen"));
const VipRewards = lazy(() => import("../../module/StaticPages/screens/VipRewardsScreen"));
const BrandGuidelinesScreen = lazy(() => import("../../module/StaticPages/screens/BrandGuidelinesScreen"));

const KycPolicyScreen = lazy(() => import("../../module/StaticPages/screens/PolicyScreens/KycPolicyScreen"));
const AmlPolicyScreen = lazy(() => import("../../module/StaticPages/screens/PolicyScreens/AmlPolicyScreen"));
const RefundPolicyScreen = lazy(() => import("../../module/StaticPages/screens/PolicyScreens/RefundPolicyScreen"));
const GdprPolicyScreen = lazy(() => import("../../module/StaticPages/screens/PolicyScreens/GdprPolicyScreen"));
const TermsOfServiceScreen = lazy(() => import("../../module/StaticPages/screens/PolicyScreens/TermsOfServiceScreen"));


const DEFAULT_MAPPING = {
	[HOMEPAGE_ROUTE]: HomeScreen,
	[CREDIT_ROUTE]: LoansScreen,
	[CONTACT_US_ROUTE]: ContactUsScreen,
	[MOBILE_APP_ROUTE]: MobileAppScreen,
	[ABOUT_US_ROUTE]: AboutUsScreen,
	[AGGREGATE_ROUTE]: AggregatorPage,
	[QUICK_BUY_ROUTE]: QuickBuyScreen,
	[STAKING_ROUTE]: StakingScreen,
	[VIP_REWARDS_ROUTE]: VipRewards,
	[BRAND_GUIDELINES_ROUTE]: BrandGuidelinesScreen,

	[KYC_POLICY_ROUTE]: KycPolicyScreen,
	[AML_POLICY_ROUTE]: AmlPolicyScreen,
	[REFUND_POLICY_ROUTE]: RefundPolicyScreen,
	[GDPR_POLICY_ROUTE]: GdprPolicyScreen,
	[TERMS_OF_SERVICE_ROUTE]: TermsOfServiceScreen,
}

const DEVELOPMENT_OVERRIDES = {};
const STAGING_OVERRIDES = {};
const PRODUCTION_OVERRIDES = {
	[HOMEPAGE_ROUTE]: ComingSoonScreen,
};

const OVERRIDE_BY_ENVIRONMENT_TYPE = {
	[DEVELOPMENT_ENVIRONMENT]: DEVELOPMENT_OVERRIDES,
	[STAGING_ENVIRONMENT]: STAGING_OVERRIDES,
	[PRODUCTION_ENVIRONMENT]: PRODUCTION_OVERRIDES,
}

export function getRouteMappingsToScreen(){
	const environmentType = Environment.environmentType;
	const overrideObject = OVERRIDE_BY_ENVIRONMENT_TYPE[environmentType];

	let routeMappings = {...DEFAULT_MAPPING};
	Object.keys(overrideObject).forEach((routeName) => {
		if(DEFAULT_MAPPING.hasOwnProperty(routeName)){
			routeMappings[routeName] = overrideObject[routeName];
		}
	})

	return routeMappings;
}
