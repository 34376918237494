/* istanbul ignore file */
import axios from 'axios';

export const getApplicationBaseUrl = function() {
	return document.location.origin;
};

const handleErrorState = (error) => {

	if(typeof error.code !== "undefined" && error.code === "ECONNABORTED") {
		return {
			message : "Api timeout response.",
			statusCode : error.code,
		};
	}

	if(typeof error.response !== "undefined" && typeof error.response.data !== "undefined" && error.response.data !== "") {
		return {
			message : error.response.data['message'],
			statusCode : error.response.data['error_code'],
		};
	}

	return {
		message : error?.response?.statusText,
		statusCode : error?.response?.status,
	};
};

export default class ApiService {
	apiVersion = "api/v1";
	baseApiUrl = "https://exchange-api.papel.eu";

	constructor() {
		this.api = axios.create({
			baseURL: this.baseApiUrl + "/" + this.apiVersion,
			timeout: 30000,
			headers: {}
		})
	}

	callAPI = async (method, url, data = {}) => {
		let response = null;
		switch (method) {
			case "GET":
				let apiGetData = await this.getMethod(url, data);
				response = await this.response(apiGetData, "GET", url, data);
				break;
			case "POST":
				let apiPostData = await this.postMethod(url, data);
				response = await this.response(apiPostData, "POST", url, data);
				break;
			case "PUT":
				let apiPutData = await this.putMethod(url, data);
				response = await this.response(apiPutData, "PUT", url, data);
				break;
			case "DELETE":
				let apiDeleteData = await this.deleteMethod(url, data);
				response = await this.response(apiDeleteData, "DELETE", url, data);
				break;
			default:
				break;
		}
		return response !== null ? response : console.warn('Api no response !!!');
	};

	getMethod = async (url, data = {}) => {
		return await this.api
			.get(url, data)
			.catch(function (error) {
				throw handleErrorState(error);
			});
	};

	postMethod = async (url, data = {}) => {
		return await this.api
			.post(url, data)
			.catch(function (error) {
				throw handleErrorState(error);
			});
	};

	putMethod = async (url, data = {}) => {
		return await this.api
			.put(url, data)
			.catch(function (error) {
				throw handleErrorState(error);
			});
	};

	deleteMethod = async (url, data = {}) => {
		return await this.api
			.delete(url, { data: data })
			.catch(function (error) {
				throw handleErrorState(error);
			});
	};

	response = async (response, method, url, data) => {
		return response;
	};
}
