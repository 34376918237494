import React, { createContext, useEffect, useState } from "react";
import { CRYPTO_CURRENCY_EXTEND} from "../CurrencyConstants";

const initialState = {
	currencies: [],
};

export const CryptoCurrencyList = createContext(initialState);
export const CryptoCurrencyListProvider = ({children}) => {
	const [currencies, setCurrencies] = useState(initialState.currencies);

	useEffect(() => {
		setCurrencies(Object.entries(CRYPTO_CURRENCY_EXTEND).map(item => item[1]));
	}, []);

	return (
		<CryptoCurrencyList.Provider value={{currencies}}>
			{children}
		</CryptoCurrencyList.Provider>
	);
};
