import React from "react";
import Marquee from "react-marquee-slider";

const LoopSlider = ({children}) => {
	return (
		<Marquee
			key={(children || []).length}
			onInit={() => {}}
			onFinish={() => {}}
			direction={'rtl'}
			velocity={30}
			minScale={0.7}
			resetAfterTries={null}
			scatterRandomly={false}
		>
			{children}
		</Marquee>
	)
};

export default LoopSlider;
