import Environment from "../Environment";
import {DEVELOPMENT_ENVIRONMENT, PRODUCTION_ENVIRONMENT, STAGING_ENVIRONMENT} from "../Environment/EnvironmentConstants";
import StaticPageLayout from "../Layouts/StaticPageLayout";
import { HOMEPAGE_ROUTE } from "./NavigationConstants";

const DEFAULT_OPTIONS = {
	layout: StaticPageLayout,
	exact: true,
}

const ALL_ENVIRONMENTS_OVERRIDE = {};
const DEVELOPMENT_OVERRIDES = {};
const STAGING_OVERRIDES = {};
const PRODUCTION_OVERRIDES = {
	[HOMEPAGE_ROUTE]: {
		hasHeader: false,
		hasFooter: false,
	}
};

const OVERRIDE_BY_ENVIRONMENT_TYPE = {
	[DEVELOPMENT_ENVIRONMENT]: DEVELOPMENT_OVERRIDES,
	[STAGING_ENVIRONMENT]: STAGING_OVERRIDES,
	[PRODUCTION_ENVIRONMENT]: PRODUCTION_OVERRIDES,
}

export function getRouteOptions(routeName){
	const environmentType = Environment.environmentType;
	const overrideObject = OVERRIDE_BY_ENVIRONMENT_TYPE[environmentType];

	let routeOptions = {...DEFAULT_OPTIONS};
	if(ALL_ENVIRONMENTS_OVERRIDE.hasOwnProperty(routeName)){
		routeOptions = {
			...routeOptions,
			...ALL_ENVIRONMENTS_OVERRIDE[routeName],
		}
	}

	if(overrideObject.hasOwnProperty(routeName)){
		routeOptions = {
			...routeOptions,
			...overrideObject[routeName],
		}
	}

	return routeOptions;
}
