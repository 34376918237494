import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export const useScrollToTopHandler = () => {
	const {listen} = useHistory()

	useEffect(() => {
		const listener = listen(() => {
			window.scrollTo({ top: 0 });
		});

		return listener
	}, [listen])
}

