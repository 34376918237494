import styled from "styled-components";
import {Link} from "react-router-dom";
import {Close} from "@material-ui/icons";

export const HeaderBarComponentContainer = styled.div`
	display: grid;
	position: relative;
	justify-content: space-between;
	align-items: center;
	grid-template-columns: 130px 1fr;
	grid-column-gap: 20px;

	padding: 40px 0;

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.md)} {
		padding: 40px 0;
		grid-template-columns: 130px auto;
	}

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.sm)} {
		padding: 30px 24px;
	}
`;

export const LogoContainer = styled(Link)`
	position: relative;
  top: -3px;
`;


export const HeaderMenuWrapper = styled.div`
	height: 100%;
	
	display: grid;
	grid-template-columns: 1fr auto;
	grid-column-gap: 20px;

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.lg)} {
		grid-column-gap: 0;
	}

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.md)} {
		width: 60vw;
		padding: 40px;
		padding-top: 80px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.sm)} {
		width: 80vw;
	}
`;

export const MenuLinksWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(4, auto);
	grid-column-gap: 60px;

	justify-content: center;
	align-items: center;

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.lg)} {
		grid-column-gap: 30px;
	}

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.md)} {
		grid-template-columns: 1fr;
		grid-row-gap: 20px;
	}
`;

export const MenuItem = styled(Link)`
	text-decoration: none;
	color: #363636;

	font-size: 14px;
	font-weight: 400;
	text-align: center;

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.md)} {
		padding: 10px 0;

		&:hover {
			background-color: #f9f9f9;
		}
	}
`;

export const AuthButtonsWrapper = styled.div`
  display: grid;
	grid-template-columns: auto auto;
	grid-column-gap: 10px;

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.lg)} {
		grid-column-gap: 0;
	}

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.md)} {
		grid-template-columns: 1fr;
		grid-row-gap: 20px;
		margin-top: 30px;
	}
`;

export const HeaderButton = styled(Link)`
	border-radius: 4px;
	padding: 10px 30px;
	text-decoration: none;

	font-size: 14px;
	font-weight: 400;
	text-align: center;
`;

export const LoginButton = styled(HeaderButton)`
	color: #003399;
`;

export const RegisterButton = styled(HeaderButton)`
	background-color: #003399;
	border: 1px solid #003399;
	color: #fff;

	&:hover {
		background-color: rgba(0, 57, 133, 0.6);
	}
`;

export const DrawerCloseButton = styled(Close)`
	position: absolute;
  color: #363636;
	font-size: 35px;
	align-self: flex-end;
	
	top: 20px;
	right: 20px;
	cursor: pointer;
`;
