import React, {createContext, useEffect, useState} from 'react';
import ExchangeService from "./ExchangeService";
import {
	CRYPTO_CURRENCIES_EXCHANGE_INDEXES,
	CRYPTO_CURRENCY_EXTEND,
	FIAT_CURRENCIES,
	FIAT_CURRENCIES_EXCHANGE_INDEXES,
} from "../Currency/CurrencyConstants";

const initialState = {
	exchangeRate: [],
	exchangeCurrencyRate: {},
	exchangeCryptoCurrencyRate: {},
};

export const ExchangeStateContext = createContext(initialState);
export const ExchangeStateProvider = ({children}) => {
	const [exchangeRate, setExchangeRate] = useState(initialState.exchangeRate);
	const [exchangeCurrencyRate, setExchangeCurrencyRate] = useState(initialState.exchangeCurrencyRate);
	const [exchangeCryptoCurrencyRate, setExchangeCryptoCurrencyRate] = useState(initialState.exchangeCryptoCurrencyRate);

	useEffect(() => {
		let isCancelled = false;
		getExchangeRate(isCancelled);
		return () => {
			isCancelled = true;
		}
	}, []);

	const getExchangeRate= (isCancelled = false) => {
		let exchangeService = new ExchangeService();
		exchangeService.getExchangeRate().then((response) => {
			if (isCancelled === false) {
				const exchangeRate = response.data.results;
				const data = [];
				let currencyRate = {};
				let cryptoCurrencyRate = {};

				for (let i = 0; i <= exchangeRate.length - 1; i++) {
					const fiatCurrencyExchangeIndex = exchangeRate[i].currency;
					const cryptoCurrencyExchangeIndex = exchangeRate[i].cryptocurrency;
					const fiatCurrencyExists = Object.values(FIAT_CURRENCIES_EXCHANGE_INDEXES).indexOf(fiatCurrencyExchangeIndex) !== -1;

					const fiatCurrencyCodeArray = Object.entries(FIAT_CURRENCIES_EXCHANGE_INDEXES).find(item => item[1] === fiatCurrencyExchangeIndex);
					const cryptoCurrencyCodeArray = Object.entries(CRYPTO_CURRENCIES_EXCHANGE_INDEXES).find(item => item[1] === cryptoCurrencyExchangeIndex);
					const fiatCurrencyCode = (fiatCurrencyCodeArray || []).shift();
					const cryptoCurrencyCode = (cryptoCurrencyCodeArray || []).shift();

					if(exchangeRate[i] && fiatCurrencyExists && fiatCurrencyCode && cryptoCurrencyCode) {
						const exchangeModel = {
							...exchangeRate[i],
							...CRYPTO_CURRENCY_EXTEND[cryptoCurrencyCode],
							cryptocurrency_code: cryptoCurrencyCode,
							currency_code: fiatCurrencyCode,
						}

						exchangeModel.currency_symbol = FIAT_CURRENCIES[fiatCurrencyCode].symbol;
						data.push(exchangeModel);

						if(!currencyRate[fiatCurrencyCode]) {
							currencyRate[fiatCurrencyCode] = [];
						}
						currencyRate[fiatCurrencyCode].push(exchangeModel);

						if(!cryptoCurrencyRate[cryptoCurrencyCode]) {
							cryptoCurrencyRate[cryptoCurrencyCode] = [];
						}
						cryptoCurrencyRate[cryptoCurrencyCode].push(exchangeModel);
					}
				}

				setExchangeCurrencyRate(currencyRate);
				setExchangeCryptoCurrencyRate(cryptoCurrencyRate);
				setExchangeRate(data);
			}
		});
	};

	return (
		<ExchangeStateContext.Provider value={{exchangeRate, exchangeCurrencyRate, exchangeCryptoCurrencyRate, getExchangeRate}}>
			{children}
		</ExchangeStateContext.Provider>
	);
};
