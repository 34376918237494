import {
	ABOUT_US_ROUTE,
	AGGREGATE_ROUTE,
	AML_POLICY_ROUTE,
	BLOG_ROUTE,
	CONTACT_US_ROUTE,
	CREDIT_ROUTE,
	GDPR_POLICY_ROUTE,
	KYC_POLICY_ROUTE,
	MOBILE_APP_ROUTE,
	QUICK_BUY_ROUTE,
	REFUND_POLICY_ROUTE,
	STAKING_ROUTE,
	TERMS_OF_SERVICE_ROUTE,
	VIP_REWARDS_ROUTE,
} from "../../../../../Navigation/NavigationConstants";

export default function useFooterLinks(){
	return [
		{title: 'Product', links: [
			{title: 'App', to: MOBILE_APP_ROUTE},
			{title: 'Quick Buy', to: QUICK_BUY_ROUTE},
			{title: 'Earn', to: STAKING_ROUTE},
			{title: 'Credit', to: CREDIT_ROUTE},
			{title: 'Rewards', to: VIP_REWARDS_ROUTE},
			{title: 'Aggregator', to: AGGREGATE_ROUTE},
		]},
		{title: 'Help', links: [
			{title: 'Help Center', to: '/'},
			{title: 'Security', to: '/'},
		]},
		{title: 'Legal', links: [
			{title: 'Terms of Service', to: TERMS_OF_SERVICE_ROUTE},
			{title: 'GDPR Policy', to: GDPR_POLICY_ROUTE},
			{title: 'Refund Policy', to: REFUND_POLICY_ROUTE},
			{title: 'AML Policy', to: AML_POLICY_ROUTE},
			{title: 'KYC Policy', to: KYC_POLICY_ROUTE},
		]},
		{title: 'About us', links: [
			{title: 'About us', to: ABOUT_US_ROUTE},
			{title: 'Contact', to: CONTACT_US_ROUTE},
			{title: 'Blog', to: {pathname: BLOG_ROUTE}, target: '_blank'},
		]}
	];
}
