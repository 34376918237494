import React from "react";
import {Switch} from "react-router-dom";

import ContextAwareRoute from "./Routes/ContextAwareRoute";
import getApplicationRoutes from "./ApplicationRoutes";
import { useScrollToTopHandler } from "./hooks/useScrollToTopHandler";

const ApplicationRouter = () => {
	useScrollToTopHandler();

	return (
		<Switch>
			{getApplicationRoutes().map((route, index) =>
				<ContextAwareRoute {...route} key={index} />
			)}
		</Switch>
	);
};

export default ApplicationRouter;
