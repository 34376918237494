import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";

import './library/Theme/DefaultTheme/fonts/style.css';
import './tailwind.css';

import ApplicationRouter from "./library/Navigation/ApplicationRouter";
import GlobalProviders from "./library/Providers/SharedProviders/GlobalProviders";

const App = () => {
	return(
		<React.Fragment>
				<GlobalProviders>
					<BrowserRouter>
						<ApplicationRouter />
					</BrowserRouter>
				</GlobalProviders>
		</React.Fragment>
	);
};

ReactDOM.render(
	<App />,
	document.getElementById('root')
);
