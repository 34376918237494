import React, {Suspense} from "react";
import { Route } from "react-router-dom";
import {StaticPageLayoutWrapper} from "./components/Styled";
import StaticPageLayoutHeader from "./components/StaticPageLayoutHeader";
import StaticPageLayoutFooter from "./components/StaticPageLayoutFooter";
import URLHelper from "../../Helpers/URLHelper";

const StaticPageLayout = ({component: Component, hasHeader = true, hasFooter = true,  ...rest}) => {
  const {hideHeader, hideFooter} = URLHelper.getUrlParams();
	const isHeaderDisplayed = hasHeader === true && hideHeader !== 'true';
	const isFooterDisplayed = hasFooter === true && hideFooter !== 'true';

	return (
    <Route {...rest} render={matchProps => (
      <StaticPageLayoutWrapper>
        {isHeaderDisplayed === true && <StaticPageLayoutHeader />}
	      <Suspense fallback={React.Fragment}>
	        <div id={'screen-content-container'} className={'overflow-y-auto'}>
		        <div>
			        <Component {...matchProps} />
		        </div>
		        {isFooterDisplayed === true && <StaticPageLayoutFooter />}
	        </div>
	      </Suspense>
      </StaticPageLayoutWrapper>
    )} />
  );
};

export default StaticPageLayout;
