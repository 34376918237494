import React from 'react';

import {ThemeProvider} from '../../Theme/ThemeProvider';
import {LocalisationProvider} from "./LocalisationProvider";
import { ExchangeStateProvider } from "../../../module/Exchange/ExchangeStateProvider";
import { FiatCurrencyListProvider } from "../../../module/Currency/FiatCurrency/FiatCurrencyListProvider";
import { CryptoCurrencyListProvider } from "../../../module/Currency/CryptoCurrency/CryptoCurrencyListProvider";

const GlobalProviders = ({children}) => {
	return (
		<ThemeProvider>
			<LocalisationProvider>
				<FiatCurrencyListProvider>
					<CryptoCurrencyListProvider>
						<ExchangeStateProvider>
							{children}
						</ExchangeStateProvider>
					</CryptoCurrencyListProvider>
				</FiatCurrencyListProvider>
			</LocalisationProvider>
		</ThemeProvider>
	);
};

export default GlobalProviders;
