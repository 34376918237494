import React from 'react';
import {Route} from "react-router-dom";

const ContextAwareRoute = ({component: Component, layout: Layout, ...rest}) => {

	const WantedRoute = () => <Route {...rest} render={matchProps => (
		<Layout component={Component} {...matchProps} {...rest} />
	)}/>
	const ComponentRendered = WantedRoute;

	return (
		<Route
			{...rest}
			render={rProps => <ComponentRendered rProps={rProps} />}
		/>
	);
};

export default ContextAwareRoute;
