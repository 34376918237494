import getBuildTypeEnvironmentVariables from './builders/BuildTypeEnvironmentVariables';

export function getEnvironment() {
  const envType = process.env.REACT_APP_APPLICATION_ENVIRONMENT;

  return Object.assign(
    {},
    getBuildTypeEnvironmentVariables(envType),
  );
}
