import styled from 'styled-components';

export const StaticPageLayoutHeaderContainer = styled.div`
	box-sizing: border-box;
	
	background-color: #FFFFFF;
	color: #748292;
`;

export const TopBarWrapper = styled.div`
	border-bottom: 1px solid #EEEEEE;
`;
