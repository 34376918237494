import React, { useContext, useEffect, useState } from "react";
import { SwipeableDrawer, useMediaQuery, useTheme } from "@material-ui/core";
import { Menu } from "@material-ui/icons";

import {
	CONTACT_US_ROUTE, LOGIN_ROUTE,
	MOBILE_APP_ROUTE,
	QUICK_BUY_ROUTE, REGISTER_ROUTE,
	VIP_REWARDS_ROUTE,
} from "../../../../../../Navigation/NavigationConstants";
import {LocalisationContext} from "../../../../../../Providers/SharedProviders/LocalisationProvider";
import WidthLimitContainer from "../../../../../../../components/Layout/WidthLimitContainer";
import CompanyLogo from "../../../../../../../components/Atomic/Branding/CompanyLogo";

import {
	HeaderBarComponentContainer,
	LogoContainer,
	HeaderMenuWrapper,
	MenuLinksWrapper,
	MenuItem,
	AuthButtonsWrapper,
	LoginButton,
	RegisterButton,
	DrawerCloseButton
} from "./components/Styled";

const HeaderBarComponent = () => {
	const {translations} = useContext(LocalisationContext);

	const theme = useTheme();
	const isSmallDevice = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	const UsedHeaderMenuContainer = isSmallDevice ? SwipeableDrawer : React.Fragment;
	const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

	useEffect(() => {
		isSmallDevice === false && setIsDrawerOpen(false);
	}, [isSmallDevice]);

  return (
    <WidthLimitContainer>
	    <HeaderBarComponentContainer>
		    <LogoContainer to={'/'}>
			    <CompanyLogo colorVariant={'blue'}/>
		    </LogoContainer>
		    {isSmallDevice === true && (
			    <Menu onClick={toggleDrawer} />
		    )}
		    <UsedHeaderMenuContainer
			    onClose={toggleDrawer}
          onOpen={toggleDrawer}
          open={isDrawerOpen}
		    >
			    <HeaderMenuWrapper>
				    {isSmallDevice === true && (
              <DrawerCloseButton color={'white'} onClick={toggleDrawer} />
            )}
				    <MenuLinksWrapper>
					    <MenuItem to={MOBILE_APP_ROUTE}>{translations.sections.header.app}</MenuItem>
					    <MenuItem to={VIP_REWARDS_ROUTE}>{translations.sections.header.rewards}</MenuItem>
					    <MenuItem to={QUICK_BUY_ROUTE}>{translations.sections.header.quickBuy}</MenuItem>
					    <MenuItem to={CONTACT_US_ROUTE}>{translations.sections.header.contact}</MenuItem>
				    </MenuLinksWrapper>

				    <AuthButtonsWrapper>
					    <LoginButton to={{pathname: LOGIN_ROUTE}} target={'_parent'}>{translations.sections.header.loginButton}</LoginButton>
					    <RegisterButton to={{pathname: REGISTER_ROUTE}} target={'_parent'}>{translations.sections.header.registerButton}</RegisterButton>
				    </AuthButtonsWrapper>
			    </HeaderMenuWrapper>
		    </UsedHeaderMenuContainer>
	    </HeaderBarComponentContainer>
    </WidthLimitContainer>
  );
};

export default HeaderBarComponent;
