import React from 'react';

import {
	StaticPageLayoutHeaderContainer,
	TopBarWrapper
} from "./components/Styled";

import TopBarComponent from "./components/TopBarComponent";
import HeaderBarComponent from "./components/HeaderBarComponent";

const StaticPageLayoutHeader = () => {
	return (
    <StaticPageLayoutHeaderContainer>
			<TopBarWrapper>
				<TopBarComponent />
			</TopBarWrapper>

	    <HeaderBarComponent />
    </StaticPageLayoutHeaderContainer>
  );
};

export default StaticPageLayoutHeader;
