import styled from "styled-components";

export const TopBarContentContainer = styled.div`
	display: flex;
	position: relative;
	height: 60px;
	align-items: center;

	@media all and ${({theme: {screen: {up, breakpoints}}}) => up(breakpoints.lg)} {
		padding: 0;
	}

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.sm)} {
		padding: 0 24px;
	}
`;

export const CurrencySelectorWrapper = styled.div`
	margin-left: 50px;
	min-width: 150px;

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.sm)} {
		& {
			display: none;
		}
	}
`;
