const breakpoints = {
	xs: '0px',
	sm: '600px',
	md: '960px',
	lg: '1280px',
	xl: '1920px'
};

const breakpointFunctions = {
	up: (breakpoint) => `(min-width: calc(${breakpoint} + 0.02px))`,
	down: (breakpoint) => `(max-width: ${breakpoint})`,
};
breakpointFunctions.between =
	(breakpointMin, breakpointMax) => `${breakpointFunctions.up(breakpointMin)} and ${breakpointFunctions.down(breakpointMax)}`;

breakpointFunctions.only = (breakpoint) => {
	const keys = Object.keys(breakpoints);
	const keyIndex = keys.indexOf(breakpoint);
	const isLast = keyIndex === keys.length -1;

	return isLast
		? `${breakpointFunctions.up(breakpoint)}`
		: `${breakpointFunctions.up(breakpoint)} and ${breakpointFunctions.down(breakpoints[keys[keyIndex+1]])}`;
}

/**
 * Legacy function which targets screen sizes up to or equal to `breakpoint (xs | sm | md | lg | xl)`
 */
breakpointFunctions.device = (breakpoint) => {
	const keys = Object.keys(breakpoints);
	const keyIndex = keys.indexOf(breakpoint);
	const isLast = keyIndex === keys.length -1;

	return isLast
		? `${breakpointFunctions.down(breakpoints[keys[keyIndex+1]])}`
		: `${breakpointFunctions.up(breakpoint)}`;
}

const SCREEN_SIZES = {breakpointFunctions, breakpoints};
export default SCREEN_SIZES;
