import React from 'react';
import CurrencySelectorListItem from "./components/CurrencySelectorListItem";

const CurrencySelectorList = ({options, selectedCurrencyCode, itemClickHandler, showFullName}) => {
  return (
	  <ul
		  tabIndex="-1"
		  role="listbox"
		  aria-labelledby="currency-selector-list"
      aria-activedescendant="listbox-item-3"
      className="max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
	  >
		  {options.map((item, index) =>
			  <CurrencySelectorListItem
			    key={index}
			    item={item}
			    isSelected={selectedCurrencyCode === item.code}
			    onClickHandler={() => itemClickHandler(item.code)}
			    showFullName={showFullName}
			  />
		  )}
	  </ul>
  );
};

export default CurrencySelectorList;
