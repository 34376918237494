import React from 'react';
import {Call, Email, Room} from "@material-ui/icons";

import {
	CompanyInformationItemContainer,
	CompanyInformationItemIconContainer,
	CompanyInformationItemText,
	CompanyInformationSectionContainer,
	CompanyInformationText,
	StyledCompanyLogo
} from "./components/Styled";

const FooterCompanyInformationSection = ({description, phoneNumber, address, emailAddress}) => {
  return (
    <CompanyInformationSectionContainer>
	    <StyledCompanyLogo />

	    <CompanyInformationText>{description}</CompanyInformationText>

	    <CompanyInformationItemContainer>
				<CompanyInformationItemIconContainer>
					<Call fontSize={"inherit"} />
				</CompanyInformationItemIconContainer>
				<CompanyInformationItemText>{phoneNumber}</CompanyInformationItemText>
	    </CompanyInformationItemContainer>

	    <CompanyInformationItemContainer>
		    <CompanyInformationItemIconContainer>
			    <Room fontSize={"inherit"} />
		    </CompanyInformationItemIconContainer>
		    <CompanyInformationItemText>{address}</CompanyInformationItemText>
	    </CompanyInformationItemContainer>

	    <CompanyInformationItemContainer>
		    <CompanyInformationItemIconContainer>
			    <Email fontSize={"inherit"} />
		    </CompanyInformationItemIconContainer>
		    <CompanyInformationItemText>{emailAddress}</CompanyInformationItemText>
	    </CompanyInformationItemContainer>
    </CompanyInformationSectionContainer>
  );
};

export default FooterCompanyInformationSection;
