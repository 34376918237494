import { FONT_SIZES } from "./fonts";
import COLORS from "./colors";
import SCREEN_SIZES from "./sizes";


const DefaultTheme = {
	colors: COLORS,
	fontSizes: FONT_SIZES,
	screen: {
		breakpoints: SCREEN_SIZES.breakpoints,
		...SCREEN_SIZES.breakpointFunctions
	}
}

export default DefaultTheme;
