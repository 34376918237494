import React from 'react';
import DefaultTheme from "./DefaultTheme";
import { ThemeProvider as StyledComponentsThemeProvider } from "styled-components";

export const ThemeProvider = ({children}) => {
	return (
		<StyledComponentsThemeProvider theme={DefaultTheme}>
				{children}
		</StyledComponentsThemeProvider>
	);
};
