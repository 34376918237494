import intl from "react-intl-universal";
import { APP_LANGUAGE_STORAGE_KEY, DEFAULT_LANGUAGE, LOCALES } from "../LocalizationConstants";

export function determineCurrentLocale(){
	let currentLocale = intl.determineLocale({
		urlLocaleKey: APP_LANGUAGE_STORAGE_KEY,
		cookieLocaleKey: APP_LANGUAGE_STORAGE_KEY,
		localStorageLocaleKey: APP_LANGUAGE_STORAGE_KEY
	});

	if (LOCALES.hasOwnProperty(currentLocale) === false) {
		currentLocale = DEFAULT_LANGUAGE;
	}

	return currentLocale;
}
