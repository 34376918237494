import React from 'react';

const SelectedItemBadge = () => (
	<span className="absolute inset-y-0 right-0 flex items-center pr-4">
    <svg
	    className="h-5 w-5"
	    viewBox="0 0 20 20"
	    fill="currentColor"
	    aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
      />
    </svg>
  </span>
);

const CurrencySelectorListItem = ({item, isSelected, onClickHandler, showFullName}) => {
	const text = showFullName === true ? item.name : item.code;

  return (
	  <li
		  onClick={onClickHandler}
		  aria-selected={isSelected}
		  id="listbox-item-0"
		  role="option"
		  className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-gray-100">
		  <div className="flex items-center">
			  <img src={item.icon} className="flex-shrink-0 h-6 w-6 rounded-full" alt="" />
			  <span className={`ml-3 block ${isSelected ? 'font-medium' : 'font-normal'} truncate uppercase`}>
				  {text}
        </span>
		  </div>

		  {isSelected === true && (<SelectedItemBadge />)}
	  </li>
  );
};

export default CurrencySelectorListItem;
