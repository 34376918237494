import React from 'react';

import whiteFullLogo from './assets/logo-full-white.png'
import blueFullLogo from './assets/default_logo.png'

const CompanyLogo = ({variant, colorVariant, ...props}) => {
	const variants = {
		full: {
			blue: blueFullLogo,
			white: whiteFullLogo,
		},
	};

  return (
    <img src={variants[variant][colorVariant]} {...props} alt={"Logo"} />
  );
};

CompanyLogo.defaultProps = {
	variant: 'full',
	colorVariant: 'white',
}

export default CompanyLogo;
