import styled from 'styled-components';

export const StaticPageLayoutFooterContainer = styled.div`
	background-color: #0a1324;
	color: #748292;
`;

export const FooterContentContainer = styled.div`
	padding: 80px 20px;
	display: flex;
	flex-direction: row;

	@media all and ${({theme: {screen: {down, breakpoints}}}) => down(breakpoints.md)} {
		flex-direction: column;
	}
`;

export const FooterCompanyInformationSectionWrapper = styled.div`
  flex: 1;
`;

export const FooterLinksSectionWrapper = styled.div`
  flex: 2;
`;

export const StaticPageLayoutFooterCopyrightBarWrapper = styled.div`
	background-color: #22314e;
	padding: 20px;
`;
