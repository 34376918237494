import Environment from "../Environment";
import {DEVELOPMENT_ENVIRONMENT, PRODUCTION_ENVIRONMENT, STAGING_ENVIRONMENT} from "../Environment/EnvironmentConstants";
import { ALL_ROUTES, HOMEPAGE_ROUTE } from "./NavigationConstants";

const DEFAULT_ROUTES_AVAILABLE = [
	...ALL_ROUTES
];

const DEVELOPMENT_OVERRIDES = DEFAULT_ROUTES_AVAILABLE;
const STAGING_OVERRIDES = DEFAULT_ROUTES_AVAILABLE;
const PRODUCTION_OVERRIDES = [
	HOMEPAGE_ROUTE
];

const OVERRIDE_BY_ENVIRONMENT_TYPE = {
	[DEVELOPMENT_ENVIRONMENT]: DEVELOPMENT_OVERRIDES,
	[STAGING_ENVIRONMENT]: STAGING_OVERRIDES,
	[PRODUCTION_ENVIRONMENT]: PRODUCTION_OVERRIDES,
}

export function getRoutesAvailable(){
	const environmentType = Environment.environmentType;
	return OVERRIDE_BY_ENVIRONMENT_TYPE[environmentType];
}
