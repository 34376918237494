import styled from "styled-components";
import CompanyLogo from "../../../../../../../../../components/Atomic/Branding/CompanyLogo";

export const CompanyInformationSectionContainer = styled.div``;

export const StyledCompanyLogo = styled(CompanyLogo)`
	height: calc(736px / 20);
`;

export const CompanyInformationText = styled.div`
	font-size: 17px;
	line-height: 26px;
	font-weight: 300;
	margin: 25px 0;
`;

export const CompanyInformationItemContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 35px;
	font-size: 15px;
	line-height: 26px;
`;

export const CompanyInformationItemIconContainer = styled.div`
	display: flex;
	align-self: flex-start;
	justify-content: center;
	align-items: center;
  background-color: white;
	border-radius: 9999px;
	padding: 10px;
	color: black;
	margin-right: 20px;
	font-size: 1.2rem;
`;

export const CompanyInformationItemText = styled.div`
  display: flex;
	align-content: center;
	justify-content: center;
`;
